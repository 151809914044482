import React from 'react';

import { Link, t, route, auth } from '@arandu/laravel-mui-admin';
import { v4 as uuid } from 'uuid';

import Divider from '@mui/material/Divider';

export default {

    loader: () => {
        if (auth().user().currentRole('admin')) {
            return [
                {
                    key: uuid(),
                    text: t('navigate.home'),
                    icon: 'homeOutlined',
                    ListItemButtonProps: {
                        component: Link,
                        to: route('home'),
                    },
                },
                {
                    key: uuid(),
                    text: 'Relatório',
                    icon: 'dashboardOutlined',
                    ListItemButtonProps: {
                        component: Link,
                        to: route('admin.timesheet.report'),
                    },
                },
                {
                    key: uuid(),
                    text: 'Financeiro',
                    icon: 'attachMoney',
                    children: [
                        route.exists('admin.account.overview') && {
                            key: uuid(),
                            text: 'Visão Geral',
                            icon: 'dashboard',
                            ListItemButtonProps: {
                                component: Link,
                                to: route('admin.account.overview'),
                            },
                        },
                        { element: <Divider key={uuid()} /> },
                        route.exists('admin.account.index') && {
                            key: uuid(),
                            text: 'Contas',
                            icon: 'accountBalance',
                            ListItemButtonProps: {
                                component: Link,
                                to: route('admin.account.index'),
                            },
                        },
                        route.exists('admin.transaction.index') && {
                            key: uuid(),
                            text: 'Lançamentos',
                            icon: 'receipt',
                            ListItemButtonProps: {
                                component: Link,
                                to: route('admin.transaction.index'),
                            },
                        },
                        route.exists('admin.cost_center.index') && {
                            key: uuid(),
                            text: 'Centros de Custo',
                            icon: 'accountBalanceWallet',
                            ListItemButtonProps: {
                                component: Link,
                                to: route('admin.cost_center.index'),
                            },
                        },
                        { element: <Divider key={uuid()} /> },
                        route.exists('admin.input.index') && {
                            key: uuid(),
                            text: 'Caderno de Insumos',
                            icon: 'book',
                            ListItemButtonProps: {
                                component: Link,
                                to: route('admin.input.index'),
                            },
                        },
                        route.exists('admin.composition.index') && {
                            key: uuid(),
                            text: 'Composições',
                            icon: 'construction',
                            ListItemButtonProps: {
                                component: Link,
                                to: route('admin.composition.index'),
                            },
                        },
                        route.exists('admin.invoice.index') && {
                            key: uuid(),
                            text: 'Notas Fiscais',
                            icon: 'fileCopyIcon',
                            ListItemButtonProps: {
                                component: Link,
                                to: route('admin.invoice.index'),
                            },
                        },
                    ],
                },
                {
                    key: uuid(),
                    text: 'CRM',
                    icon: 'people',
                    children: [
                        route.exists('admin.lead.index') && {
                            key: uuid(),
                            text: 'Cadastro de Leads',
                            icon: 'personPinCircleOutlinedIcon',
                            ListItemButtonProps: {
                                component: Link,
                                to: route('admin.lead.index'),
                            },
                        },
                        route.exists('admin.customer.index') && {
                            key: uuid(),
                            text: 'Cadastro de Clientes',
                            icon: 'peopleOutline',
                            ListItemButtonProps: {
                                component: Link,
                                to: route('admin.customer.index'),
                            },
                        },
                        route.exists('admin.budget.index') && {
                            key: uuid(),
                            text: 'Orçamentos',
                            icon: 'receiptLong',
                            ListItemButtonProps: {
                                component: Link,
                                to: route('admin.budget.index'),
                            },
                        },
                        route.exists('admin.contract.index') && {
                            key: uuid(),
                            text: 'Contratos',
                            icon: 'gavelIcon',
                            ListItemButtonProps: {
                                component: Link,
                                to: route('admin.contract.index'),
                            },
                        },
                        route.exists('admin.project.index') && {
                            key: uuid(),
                            text: 'Projetos',
                            icon: 'construction',
                            ListItemButtonProps: {
                                component: Link,
                                to: route('admin.project.index'),
                            },
                        },
                        // route.exists('admin.lead.index') && {
                        //     key: uuid(),
                        //     text: 'Oportunidades',
                        //     icon: 'contactsIcon',
                        //     ListItemButtonProps: {
                        //         component: Link,
                        //         to: route('admin.lead.index'),
                        //     },
                        // },
                        { element: <Divider key={uuid()} /> },
                        route.exists('admin.address.index') && {
                            key: uuid(),
                            text: 'Endereços',
                            icon: 'locationOn',
                            ListItemButtonProps: {
                                component: Link,
                                to: route('admin.address.index'),
                            },
                        },
                    ]
                },
                {
                    key: uuid(),
                    text: 'Operação',
                    icon: 'engineering',
                    children: [
                        route.exists('admin.timesheet.index') && {
                            key: uuid(),
                            text: 'Apontamentos',
                            icon: 'schedule',
                            ListItemButtonProps: {
                                component: Link,
                                to: route('admin.timesheet.index'),
                            },
                        },
                        route.exists('admin.task.index') && {
                            key: uuid(),
                            text: 'Tarefas',
                            icon: 'engineering',
                            ListItemButtonProps: {
                                component: Link,
                                to: route('admin.task.index'),
                            },
                        },
                        route.exists('admin.planning.index') && {
                            key: uuid(),
                            text: 'Planejamentos',
                            icon: 'newspaperIcon',
                            ListItemButtonProps: {
                                component: Link,
                                to: route('admin.planning.index'),
                            },
                        },
                        // route.exists('admin.feedback.index') && {
                        //     key: uuid(),
                        //     text: 'Feedbacks',
                        //     icon: 'newspaperIcon',
                        //     ListItemButtonProps: {
                        //         component: Link,
                        //         to: route('admin.feedback.index'),
                        //     },
                        // },
                        // route.exists('admin.form.index') && 
                        {
                            key: uuid(),
                            text: 'Formulários',
                            icon: 'questionAnswerOutlined',
                            ListItemButtonProps: {
                                component: Link,
                                to: route('admin.form.index'),
                            },
                        },
                        {
                            key: uuid(),
                            text: 'Avisos',
                            icon: 'notificationsActiveIcon',
                            ListItemButtonProps: {
                                component: Link,
                                to: route('admin.notice.index'),
                            },
                        }
                    ],
                },
                {
                    key: uuid(),
                    text: 'Acessos',
                    icon: 'block',
                    children: [
                        route.exists('admin.user.index') && {
                            key: uuid(),
                            text: t('models.user.plural'),
                            icon: 'peopleOutline',
                            ListItemButtonProps: {
                                component: Link,
                                to: route('admin.user.index'),
                            },
                        },
                        { element: <Divider key={uuid()} /> },
                        route.exists('admin.role.index') && {
                            key: uuid(),
                            text: t('models.role.plural'),
                            icon: 'security',
                            ListItemButtonProps: {
                                component: Link,
                                to: route('admin.role.index'),
                            },
                        },
                    ]
                },
                route.exists('admin.site_options.edit') && {
                    key: uuid(),
                    text: 'Configurações',
                    icon: 'settings',
                    ListItemButtonProps: {
                        component: Link,
                        to: route('admin.site_options.edit'),
                    },
                },

            ];

        }

        if (auth().user().currentRole('developer')) {
            return [
                {
                    key: uuid(),
                    text: 'Board',
                    icon: 'board',
                    ListItemButtonProps: {
                        component: Link,
                        to: route('home'),
                    },
                },
                // {
                //     key: uuid(),
                //     text: 'Usuário',
                //     icon: 'user',
                //     category: 'daily',
                //     ListItemButtonProps: {
                //         component: Link,
                //         to: `/user/${auth().user().id}`,
                //     },
                // },
                {
                    key: uuid(),
                    text: 'Atividades',
                    icon: 'assistantOutlined',
                    children: [
                        {
                            key: uuid(),
                            text: 'Kanban',
                            ListItemButtonProps: {
                                component: Link,
                                to: route('admin.task.kanban'),
                            },
                        },
                        {
                            key: uuid(),
                            text: 'Listagem',
                            ListItemButtonProps: {
                                component: Link,
                                to: route('admin.task.dashboard'),
                            },
                        },
                    ]
                },
                // {
                //     key: uuid(),
                //     text: 'Usuário', 
                //     icon: 'user',
                //     category: 'daily',
                //     ListItemButtonProps: {
                //         component: Link,
                //         to: `/user/${auth().user().id}`,
                //     },
                // },
                // {
                //     key: uuid(),
                //     text: 'Controle de Horas',
                //     icon: 'clock',
                //     ListItemButtonProps: {
                //         component: Link,
                //         to: route('admin.timesheet.index'),
                //     },
                // },
                route.exists('admin.planning.index') && {
                    key: uuid(),
                    text: 'Planejamentos',
                    icon: 'newspaperIcon',
                    ListItemButtonProps: {
                        component: Link,
                        to: route('admin.planning.index'),
                    },
                },
            ];

        }

        if (auth().user().currentRole('manager')) {
            return [
                {
                    key: uuid(),
                    text: 'Board',
                    icon: 'board',
                    category: 'daily',
                    ListItemButtonProps: {
                        component: Link,
                        to: route('home'),
                    },
                },
                // {
                //     key: uuid(),
                //     text: 'Usuário',
                //     icon: 'user',
                //     category: 'daily',
                //     ListItemButtonProps: {
                //         component: Link,
                //         to: `/user/${auth().user().id}`,
                //     },
                // },
                {
                    key: uuid(),
                    text: 'Atividades',
                    icon: 'assistantOutlined',
                    category: 'daily',
                    children: [
                        {
                            key: uuid(),
                            text: 'Kanban',
                            ListItemButtonProps: {
                                component: Link,
                                to: route('admin.task.kanban'),
                            },
                        },
                        {
                            key: uuid(),
                            text: 'Listagem',
                            ListItemButtonProps: {
                                component: Link,
                                to: route('admin.task.dashboard'),
                            },
                        },
                    ]
                },
                // {
                //     key: uuid(),
                //     text: 'Usuário', 
                //     icon: 'user',
                //     category: 'daily',
                //     ListItemButtonProps: {
                //         component: Link,
                //         to: `/user/${auth().user().id}`,
                //     },
                // },
                route.exists('admin.planning.index') && {
                    key: uuid(),
                    text: 'Planejamentos',
                    icon: 'newspaperIcon',
                    ListItemButtonProps: {
                        component: Link,
                        to: route('admin.planning.index') + '?filters=%7B"status"%3A20%7D',
                    },
                },
                route.exists('admin.project.report') &&
                {
                    key: uuid(),
                    text: 'Horas Consumidas',
                    icon: 'barChart',
                    category: 'management',
                    ListItemButtonProps: {
                        component: Link,
                        to: route('admin.project.report'),
                    },
                },
                {
                    key: uuid(),
                    text: 'Controle de Horas',
                    icon: 'clock',
                    ListItemButtonProps: {
                        component: Link,
                        to: '/timesheets/report',
                    },
                },
                // route.exists('admin.project.report') &&
                {
                    key: uuid(),
                    text: 'Projeto',
                    icon: 'project',
                    category: 'management',
                    ListItemButtonProps: {
                        component: Link,
                        to: '/project',
                    },
                },
                // route.exists('admin.lead.index') && {
                //     key: uuid(),
                //     text: 'Oportunidades',
                //     icon: 'contactsIcon',
                //     ListItemButtonProps: {
                //         component: Link,
                //         to: route('admin.lead.index'),
                //     },
                // },
                // route.exists('admin.feedback.index') && {
                //     key: uuid(),
                //     text: 'Feedbacks',
                //     icon: 'newspaperIcon',
                //     ListItemButtonProps: {
                //         component: Link,
                //         to: route('admin.feedback.index'),
                //     },
                // },
            ];

        }

    },

};

